import React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { PageProps, graphql } from 'gatsby';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import ButtonLink from '../../../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../../../components/Icon/Icon';
import { useLocaleContext } from '../../../../context/Locale';
import { getPathFromRouteKey } from '../../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../../config/RouteKeys';
import ExternalLink from '../../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../../data/common/common';

type Props = PageProps & WithTranslation;

const Internship: React.FC<Props> = (props: Props) => {
  const { t, data } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('internship.interns.seo.title'),
    },
    page: {
      title: t('internship.interns.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-careers-internship.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const getTable = () => {
    const list = t('internship.interns.leo.questions', { returnObjects: true }) as {
      question: string;
      answer: string;
    }[];

    return (
      <table className="internship">
        <tbody>
          {list.map((item) => {
            return (
              <React.Fragment key={item.question}>
                <tr>
                  <th>{item.question}</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      <Trans>{item.answer}</Trans>
                    </p>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  const imageUrl = (data as any).allFile.edges.find((edge: any) =>
    edge.node.name.includes(`-${activeLocaleTag}`)
  ).node.publicURL;

  return (
    <Layout options={layoutOptions} className="Careers Careers_intern">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="Careers__generic-gap">
              <h2 className="h3">{t('internship.interns.leo.headingThree')}</h2>
              {getTable()}
            </div>
            {/* <div className="Careers__generic-gap">
              <p>
                <Trans t={t} i18nKey="internship.interns.leo.body">
                  <ExternalLink href={CommonData.websites.htb.linkedin} />
                </Trans>
              </p>
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.CAREERS)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
                styleOptions={{ isInline: true }}
                linkOptions={{ shouldEnableActiveState: false }}
              >
                {t('heading')}
              </ButtonLink>
            </div> */}
          </section>
          <aside className="column column-33">
            <div className="Careers__generic-gap">
              <div className="img-container">
                <img src={imageUrl} alt={t('internship.interns.leo.ariaImageLabel')} />
              </div>
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: { relativePath: { regex: "/careers/interns/LeoSandler-/" }, ext: { eq: ".jpg" } }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.CAREERS)(Internship);
